<template>
  <i-container
    v-if="response"
    class="_display-flex _flex-direction-column bock--exhibit-container"
  >
    <div
      class="
        _flex-grow-1
        _display-flex
        _justify-content-center
        _align-items-center
      "
    >
      <prismic-image
        v-if="item.primary.image.url"
        :field="format"
        class="image -responsive bock--hover"
        :class="style"
        @click="visible = true"
      />
    </div>
    <i-container class="_margin-top-6">
      <i-row>
        <i-column :xs="true">
          <i-button
            link
            variant="dark"
            size="sm"
            v-if="prev"
            @click="
              $router.push({
                name: 'Mural',
                params: { id: slugify(prev.primary.title1) },
              })
            "
          >
            <i-icon icon="chevron-left" class="_margin-right-1-2"></i-icon>
            {{ prev.primary.title1 }}
          </i-button>
        </i-column>
        <i-column :xs="true" class="_text-right">
          <i-button
            link
            variant="dark"
            size="sm"
            v-if="next"
            @click="
              $router.push({
                name: 'Mural',
                params: { id: slugify(next.primary.title1) },
              })
            "
          >
            {{ next.primary.title1 }}
            <i-icon icon="chevron-right" class="_margin-left-1-2"></i-icon>
          </i-button>
        </i-column>
      </i-row>
    </i-container>
    <div
      class="
        bock--exhibit-nav
        _margin-bottom-xs-4
        _margin-bottom-sm-4
        _margin-bottom-md-1
        _margin-bottom-lg-1
        _margin-bottom-xl-1
        _margin-top-1
      "
    >
      <i-nav size="sm" class="_flex-shrink-1">
        <i-nav-item
          class="_margin-left-0 _padding-left-0"
          :to="{ name: 'Murals' }"
          ><span>More Murals</span></i-nav-item
        >
      </i-nav>
    </div>
    <i-modal class="zoom" v-model="visible">
      <prismic-image
        v-if="item.primary.image.url"
        :field="format"
        class="image -responsive bock--hover"
        @click="visible = false"
      />
      <i-button
        size="sm"
        link
        variant="primary"
        @click="visible = false"
        class="_padding-left-0 _margin-top-1"
        >Close</i-button
      >
    </i-modal>
  </i-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Mural",
  data() {
    return {
      response: null,
      body: [],
      item: null,
      slug: "",
      total: 0,
      index: 0,
      next: null,
      prev: null,
      visible: false,
      info: null,
    };
  },
  computed: {
    ratio() {
      let n = Number;
      if (this.response) {
        n = Number(
          (
            this.item.primary.image.dimensions.width /
            this.item.primary.image.dimensions.height
          ).toFixed(1)
        );
      } else {
        n = 0.0;
      }
      return n;
    },
    format() {
      if (this.ratio >= 1.5) {
        return this.item.primary.image["lg-wide"];
      } else if (this.ratio <= 0.7) {
        return this.item.primary.image["lg-tall"];
      } else {
        return this.item.primary.image["lg-wide"];
      }
    },
    style() {
      if (this.ratio >= 1.5) {
        return "bock--image-wide";
      } else if (this.ratio <= 0.7) {
        return "bock--image-tall";
      } else {
        return "bock--image-square";
      }
    },
  },
  methods: {
    ...mapActions(["setMural"]),
    async getContent() {
      const response = await this.$prismic.client.getSingle("murals");
      this.response = response;
      this.total = response.data.body.length;
      response.data.body.forEach((item, i) => {
        this.slug = this.slugify(item.primary.title1);
        let mural = {
          id: this.slug,
          item: item,
        };
        this.body.push(mural);
        if (this.slug === this.$route.params.id) {
          this.item = item;
          this.index = i;
          if (i == 0) {
            this.prev = this.response.data.body[this.total - 1];
            this.next = this.response.data.body[i + 1];
          } else if (i == this.total - 1) {
            this.prev = this.response.data.body[i - 1];
            this.next = this.response.data.body[0];
          } else {
            this.prev = this.response.data.body[i - 1];
            this.next = this.response.data.body[i + 1];
          }
          console.log(this.prev, this.next);
        }
      });
      this.getInfo();
    },
    getInfo() {
      this.info = {
        title: this.item.primary.title1,
        location: this.item.primary.location,
        year: this.item.primary.year,
        info: this.item.primary.info,
        truncate: this.truncate(this.item.primary.info),
        thumb: this.item.primary.image.square,
      };
      this.$store.dispatch("setMural", this.info);
    },
    deleteInfo() {
      this.info = {
        title: null,
        location: null,
        year: null,
        info: null,
        truncate: null,
        thumb: null,
      };
      this.$store.dispatch("setMural", this.info);
    },
    truncate(string) {
      let value = string[0].text.split(" ");
      if (value.length > 32 || string.length > 1) {
        value = value.slice(0, 32).join(" ") + "...";
      } else {
        value = string[0].text;
      }
      return value;
    },
    slugify(string) {
      const a =
        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
      const b =
        "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
      const p = new RegExp(a.split("").join("|"), "g");
      return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style lang="scss">
.mural:hover {
  cursor: pointer;
}
</style>
